<template>
  <div class="app-container">
    <el-upload
      class="upload-demo"
      accept=".xls,.xlsx"
      :action="uploadUrl"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :on-error="onError"
      :on-success="onSuccess"
      :before-remove="beforeRemove"
      multiple
      :limit="1"
      :on-exceed="handleExceed"
      :file-list="fileList"
      :headers="headers"
    >
      <el-button size="small" type="primary">批量上传</el-button>
    </el-upload>

    <el-table
      :data="tableData"
      style="width: 100%"
      v-loading="listLoading"
      border
      fit
      highlight-current-row
      class="tableList3"
    >
      <el-table-column prop="id" label="ID" width="100px" />
      <el-table-column prop="provider" label="服务商"></el-table-column>
      <el-table-column prop="type" label="类型"></el-table-column>
      <el-table-column prop="host" label="Host"></el-table-column>
      <el-table-column prop="port" label="端口"></el-table-column>
      <el-table-column prop="username" label="用户名"></el-table-column>
      <el-table-column prop="password" label="密码"></el-table-column>
      <el-table-column prop="country" label="国家"></el-table-column>
      <el-table-column prop="notes" label="备注"></el-table-column>
      <el-table-column prop="cooling_time" label="可用时间"></el-table-column>
      <el-table-column prop="create_time" label="入库时间"></el-table-column>
      <!-- <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            @click="removeKeyword(scope.row.id)"
            type="text"
            size="small"
          >
            删除关键词
          </el-button>
        </template>
      </el-table-column> -->
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="pageIndex"
      :page-sizes="[pageSize, 20, 50, 100]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    ></el-pagination>
  </div>
</template>

<script>
import { getProxyList } from '@/api/browser'
import { getCookie } from '@/plugins/axios'
export default {
  data() {
    return {
      uploadUrl: process.env.VUE_APP_BASE_API + '/browser/proxies:batch_upload',
      fileList: [],
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      listLoading: true,
      headers: {
        'X-CSRFToken': getCookie('csrftoken')
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    // 获取服务列表
    async fetchData() {
      this.listLoading = true
      const { items, all_count: total } = await getProxyList({
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      })
      this.tableData = items
      this.total = total
      this.listLoading = false
    },

    handleSizeChange(val) {
      // 当每页显示条数发生变化时
      this.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      // 当页码发生变化时
      this.pageIndex = val
      this.fetchData()
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    handlePreview(file) {
      console.log(file)
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      )
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    onError(error) {
      const response = JSON.parse(error.message)
      // this.$message.error(res.message)
      const { status, message, data } = response
      console.log(status, message, data)
      // console.log(typeof data)
      if (status === 400) {
        if (data === null || data === undefined) {
          this.$message.error(message)
        } else {
          for (const errorField in data) {
            const errorInfos = data[errorField]
            for (var errorInfoIndex in errorInfos) {
              setTimeout(() => {
                this.$message.error(
                  errorField + ': ' + errorInfos[errorInfoIndex]
                )
              }, 1)
            }
          }
        }
      } else if (status === 401) {
        // this.$alert('登录超时，请重新登录', '提示', {
        //   confirmButtonText: '确定',
        //   callback: this.$router.push('/login')
        // })
        this.$message.warning('登录超时，请重新登录')
        const origin = window.localStorage.getItem('origin')
        if (typeof origin === 'undefined' || origin == null || origin === '') {
          this.$router.push({ path: '/login' })
        } else {
          window.location.href = origin
        }
      } else {
        this.$message.error(message)
      }
    },
    onSuccess(res) {
      const { message } = res
      if (res.code === 201) {
        this.$message.success(message)
        this.fetchData()
      } else {
        this.$message.error(res.message)
      }
      this.$refs.upload.clearFiles()
    }
  }
}
</script>

<style></style>
