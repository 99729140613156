import request from '@/plugins/axios'

// ================代理接口==============
export function getProxyList(params) {
  // 分页获取代理接口
  return request({
    url: 'browser/proxies',
    method: 'get',
    params
  })
}
